@import "../variables";

.classesHeaderContent {
    position: relative;
    height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/heroImages/membershipsHero.png");
    background-size: cover;
    background-position: top;
    padding-left: 40px;

    .wrapper {
        .classesHeaderText {
            color: $white;
            h1 {
                text-align: left;
                line-height: 1;
                margin-bottom: 20px;
                color: white;
                font-size: 4.5rem;
            }
        }
    }
}

.groupListContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 4%;
    .classContainer{
        background-color: $black;
        color:$white;
        width: calc( (100% / 2) - 20px);
        margin-bottom: 40px;
        padding:10px 40px;
        h4{
            font-size: 2rem;
        }
        .classInfo{
            margin: 40px 0;
            width:100%;
            p{
                width:auto;
                margin:0;
            }
        }
    }
        
    
}
// .groupListContainer :nth-child(odd){
//     margin-right: 40px;
// }

.classContainerodd{
    margin-right: 40px;
}

.lastClassList {
    margin-right: 0;
    margin-bottom: 0;
    background-color: $black;
    color: $white;
    width: calc((100% / 2) - 20px);
    padding: 10px 40px;

    h4 {
        font-size: 2rem;
    }

    .classInfo {
        margin: 40px 0;
        width: 100%;

        p {
            width: auto;
            margin: 0;
        }
    }
}


.widgetNameContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .widgetATag {
            display: "block";
            float: "right";
            margin: "16px 16px 0 0";
        }
    
        .widgetImgTag {
            height: "39px";
            width: "267px";
        }
}
.widgetError{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        margin-bottom: 40px;
    }
}


//Wellness Widget styles

//End of Wellness Widget Styles

.classesMembershipSection{
    background-color: $black;
    color: $white;
    min-height: 100vh;
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .classMembership{
            text-align: center;
            h2{
                margin: 3rem 0;
            }
            p{
                margin-bottom: 3rem;
            }
        }
        .membershipOptionList{
            display: flex;
            justify-content: center;
            .optionInfo{
                background-color: $white;
                width: calc( (100% /  3) - 4rem);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .membershipName{
                    height: 8rem;
                    background-color: $grey;
                    padding: 1rem 2rem 1rem 0.5rem;
                    width:100%;
                    h4{
                        padding: 0;
                        margin:0;
                    }
                    p{
                        margin:0;
                        line-height: 1.5;
                    }
                }
                .membershipCost{
                    width: 100%;
                    margin: 20px 0 0 0;
                    color: $blue;
                    border-top: 1.5px solid $blue;
                    border-bottom: 1.5px solid $blue;
                    display: flex;
                    justify-content: center;
                    padding:0;
                    h3, p{
                        font-weight: bold;
                    }
                    p{
                        font-size: 1rem;
                        margin:0 0 0 0.5rem;
                    }
                }
                button {
                    margin: 3rem 0;
                    text-wrap: nowrap;
                }
            }
        }
        .membershipOptionList :nth-child(2){
            margin: 0 2rem;
        }
    }
}

.membershipTrialSection{
    background-color: $black;
    color: $white;
    min-height: 50vh;
    .wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2,p{
            text-align: center;
        }
        h2{
            width: 65%;
        }
        p{
            width:60%;
            margin:2rem 0;
        }
        button{
            width:14rem
        }
    }
}



@media(max-width: 960px) {

    .pltfrmClasses {

        .navContainer {
            background-color: $white;
        }

        .navLinks.active {
            background-color: $white;
        }
    }

        .classesHeaderContent {
            padding: 0;
            height: 100vh;
            background-position: center;
    
            .wrapper {
                .classesHeaderText {
                    h1 {
                        text-align: center;
                    }
                }
            }
        }

        .classesMembershipSection {
            .wrapper {
                .membershipOptionList {
                    flex-wrap: wrap;
                    .optionInfo {
                        min-width: 22rem;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
}

@media(max-width:832px){
    .groupListContainer {
            .classContainer, .lastClassList {
                padding: 10px 20px;
    
        }
    }
}


@media(max-width: 750px) {
    .groupListContainer {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items:center;
            margin-top: 4%;
            .classContainer, .lastClassList {
                width: 100%;
                padding:10px;
            }
    
        }
    
        .groupListContainer :nth-child(odd) {
            margin-right: 0;
        }


        .classesMembershipSection {
            .wrapper {
                .membershipOptionList {
                    flex-wrap: wrap;

                    .optionInfo {
                        max-width: 24rem;
                        width:100%;
                    }
                }
                .membershipOptionList :nth-child(2) {
                    margin: 0;
                    margin-bottom: 2rem;
                }
            }
        }
        
        .membershipTrialSection {
            .wrapper {
                h2 {
                    width: 90%;
                }

                p {
                    width: 80%;
                }

                button {
                    width: auto;
                }
            }
        }
}